export const messages = {
  signUp: "Регистрация",
  signUp_create: "Создание аккаунта",
  signUp_companyToken: "Регистрационный номер компании",
  signUp_email: "Email",
  signUp_password: "Пароль",
  singUp_password_repeat: "Повторите пароль",
  signUp_submit: "Подтвердить",
  signUp_logIN: "Войдите",
  signUp_success:
    "Успешно! Подвердите регистрацию, перейдя по ссылке, отправленной на ваш email",
  signUp_errors: "Пользователь с указанным логином уже существует",
  signUp_question: "Уже есть аккаунт?",

  login_title: "Вход в аккаунт",
  login_subtitle: "Для аутентификации введите ваш логин и пароль",
  login: "Электронная почта",
  password: "Пароль",
  logIn_forgot: "Восстановление пароля",
  logIn_remember: "Запомнить меня",
  entry: "Вход",
  logIn_create_account: "Зарегистироваться",
  login_question: "У вас нет аккаунта?",
  logIn_message_success: "Авторизация успешна",
  login_message_errors: "Неверный логин и(или) пароль",
  login_message_warning: "",
  login_email_notConfirm:
    "Email не подтвержден. Письмо для подтверждения отправлено повторно",

  token_updated_message: "Сессия успешна продлена",
  token_message_expiration:
    "Ваша сессия истекла, повторите попытку авторизации",
  reset_password: "Пароль успешно обновлен",
  reset_password_error:
    "Не удалось сбросить пароль, попробуйте еще раз или запросите письмо повторно",

  PWReset: "Сбросить пароль",
  PWReset_password_reset: "Сброс пароля",
  PWReset_reset_link: "Ссылка для сброса будет отправлена на ваш email",
  PWReset_email: "Email",
  PWReset_reset_password: "Сброс пароля",
  PWReset_go_back: "Назад",
  send_letter: "Письмо для сброса пароля отправлено вам на email",
  error_send_letter:
    "Произошла ошибка при отправке письма! Запросите письмо повторно",

  data_receiving_error: "Ошибка получения данных",
  data_functions_error: "Список функций не получен",

  change_password_menu: "Сменить пароль",
  log_out: "Выйти",

  change_password_title: "Сменить пароль",
  current_password_title: "Текущий пароль",
  change_current_password: "Пароль успешно изменен",
  error_current_password:
    "Произошла ошибка при изменении пароля. Попробуйте еще раз",
  change_password_question: "Вы хотите отменить смену пароля?",
  placeholder_current_password: "Введите пароль",
  new_password: "Новый пароль",
  placeholder_new_password: "Введите новый пароль",
  confirm_password_title: "Повторите пароль",
  create_new_password_title: "Создание нового пароля",

  verification_title: "Ваш email подтвержден!",
  verification_subtitle:
    "Чтобы приступить к работе необходимо авторизоваться, нажмите на кнопку ниже",
  verification: "Авторизоваться",

  save: "Сохранить",
  edit: "Редактировать",
  cancel: "Отмена",
  leave_page: "Покинуть",
  stay_page: "Остаться",
  restore: "Восстановить",
  overwrite: "Перезаписать",
  confirm: "Подтвердить",
  delete: "Удалить",
  button_back: "Назад",
  buttonYes: "Да",
  buttonNo: "Нет",
  approve: "Подтвердить",
  add: "Добавить",
  update: "Обновить",
  upload: "Добавить",
  revoke: "Отозвать токены",
  create: "Создать",
  create_user: "Создать пользователя",
  download: "Скачать",

  input_required: "Поле обязательно для заполнения",
  input_maxLength: "Для поля максимум 38 знаков",
  validate_password:
    "Пароль минимум 6 символов, строчные/заглавные буквы, цифры, как минимум один символ #?!@$%^&*-+",
  validate_email: "Неверный формат адреса электронной почты",
  match_password: "Пароли должны совпадать",

  tableSearch: "Поиск",
  table_company_name: "Название",
  table_company_token: "Токен",
  table_company_server_address: "Адрес сервера",
  table_company_revoke_token: "Отозвать токен компании",
  table_company_update_token: "Обновить токен компании",

  table_empty: "Нет данных для отображения",
  table_page_text: "Показывать по",
  table_select_allPages: "Все",

  companyList: "Компании",
  usersList: "Пользователи",
  updateSoftware: "Обновление ПО",
  nameOurCompany: "АдминПанель",

  firmWares_title: "Список прошивок",
  completed: "Завершено",
  in_Progress: "В процессе",
  uploader_question: "Вы хотите отменить загрузку файла?",
  uploader_title: "Загрузка файла",
  uploader_file_name: "Название файла",
  uploader_firmWare_name: "Название прошивки(.hex)",
  uploader_version: "Версия прошивки",
  placeholder_file_name: "Введите название файла",
  placeholder_version: "Укажите версию прошивки",
  placeholder_firmWare_name: "Введите название прошивки",
  file_upload_success: "Файл успешно загружен",
  file_upload_error: "Не удалось загрузить файл",

  update_question: "Вы хотите обновить выбранную прошивку?",
  update_start: "Обновление началось",
  update_firmWare_error: "Не удалось обновить прошивку",

  company_list_title: "Список компаний",
  createCompany_success: "Компания успешно создана",
  createCompany_error: "Не удалось создать компанию",
  create_company_title: "Создание новой компании",
  create_company_name: "Название компании",
  create_company_name_host: "Адрес сервера",
  create_company_name_docker: "Название для Docker",
  placeholder_name_host: "Введите адрес сервера",
  create_company_user_name: "Имя пользователя",
  placeholder_user_name: "Введите имя пользователя",
  placeholder_name_company: "Введите название компании",
  placeholder_name_docker: "Введите имя Docker",
  placeholder_name_docker_condition: "Используйте только латинские буквы в имени Docker",
  company_secret_code: "Секретный код",
  placeholder_secret_code: "Введите секретный код",
  company_server_address: "Адрес сервера",
  placeholder_server_address: "Введите адрес сервера",
  company_backupHost: "Адрес резервного сервера",
  placeholder_backupHost: "Введите адрес резервного сервера",
  company_secretCode: "Секретный код",
  placeholder_secretCode: "Введите секрентный код",
  company_token: "Токен",
  placeholder_token: "Введите Токен",
  keep_alive_interval: "Время сохранения соединения модуля с брокером",
  company_vehicle_port: "Порт VehicleServer",
  company_broker_port: "Порт MqttBroker",
  company_server_port: "Порт MqttServer",
  company_rabbit_mq_port: "Порт RabbitMQ",
  CanDataServerPort_1: "Порт сервера данных CAN 1",
  CanDataServerPort_2: "Порт сервера данных CAN 2",
  DatabasePort: "Порт базы данных",
  Database_sms: "Отправлено сообщений",
  company_creation_date: "Дата создания",
  placeholder_vehicle_port: "Введите порт",
  placeholder_vehicle_port_broker: "Введите порт",
  placeholder_keep_alive_interval: "Введите время",
  company_email: "Email для подключения к брокеру",
  placeholder_company_email: "Введите email",
  company_validate_email: "Неверный формат адреса электронной почты",
  company_password: "Пароль для подключения к брокеру",
  placeholder_company_password: "Введите пароль",
  company_validate_password:
      "Пароль минимум 6 символов, строчные/заглавные буквы, цифры, как минимум один символ #?!@$%^&*-+",
  positive_number: "Допустимы только положительные числа",
  create_company_question: "Вы хотите отменить создание компании?",

  edit_company_title: "Редактирование данных компании",
  updateCompany_success: "Компания успешно обновлена",
  updateCompany_error: "Не удалось обновить компанию",
  update_company_question: "Вы хотите отменить редактирование компании?",
  save_company_question: "Вы хотите сохранить изменения?",

  delete_company_success: "Компания удалена",
  delete_company_error: "Не удалось удалить компанию",
  delete_company_question: "Вы действительно хотите удалить компанию?",

  revoke_company_token_success: "Токен компании успешно отозван",
  revoke_company_token_error: "Не удалось отозвать токен компании",
  revoke_token_question: "Вы действительно хотите отозвать токен?",

  update_company_token: "Обновление токена",
  update_company_token_success: "Токен компании успешно обновлен",
  update_company_token_error: "Не удалось обновить токен компании",
  update_company_token_question: "Вы хотите отменить обновление токена?",

  information_ports: "Список портов",
  vehiclePort: "VehiclePort",
  brokerPort: "BrokerPort",

  user_list_title: "Список пользователей",
  user_email_confirmed: "Подтвержден",
  user_email_notConfirmed: "Не подтвержден",
  user_id: "Id пользователя",
  user_email: "Email пользователя",
  user_email_status: "Статус Email",
  user_email_confirm: "Подтвержден",
  user_email_not_confirm: "Не подтвержден",
  user_creation_date: "Дата создания пользователя",
  user_roles: "Роли",
  user_refresh_token_expiry_time: "Дата истечения refresh-токена",
  user_revoke_token_question:
    "Вы действительно хотите отозвать токен пользователя?",
  user_revoke_token_success: "Токен пользователя успешно отозван",
  user_revoke_token_error: "Не удалось отозвать токен пользователя",
  user_revoke_all_token_question:
    "Вы хотите отозвать токены всех пользователей?",
  user_revoke_all_token_success: "Токены всех пользователей успешно отозваны",
  user_revoke_all_token_error: "Не удалось отозвать токены пользователей",
  user_revoke_token: "Отозвать токен пользователя",

  create_new_user: "Создание нового пользователя",
  userName: "Никнейм пользователя",
  placeholder_userName: "Введите никнейм пользователя",
  email: "Email пользователя",
  placeholder_email: "Введите email пользователя",
  login_user: "Логин пользователя",
  placeholder_login_user: "Введите логин пользователя",
  password_user: "Пароль пользователя",
  placeholder_password_user: "Введите пароль пользователя",
  firstName: "Имя пользователя",
  placeholder_firstName: "Введите имя пользователя",
  secondName: "Фамилия пользователя",
  placeholder_secondName: "Введите фамилию пользователя",
  dateBirth: "Дата рождения",
  placeholder_dateBirth: "Выберите дату рождения",
  user_gender: "Пол",
  user_man: "Мужской",
  user_female: "Женский",
  createUser_success: "Пользователь успешно создан",
  createUser_error: "Не удалось создать пользователя",
  placeholder_user_gender: "Выберите пол",
  users_roles: "Роль",
  placeholder_users_roles: "Выберите роль",
  user_create_question: "Вы хотите отменить создание пользователя?",
  user_update_question: "Вы хотите отменить редактирование пользователя?",
  updateUser_success: "Пользователь успешно обновлен",
  updateUser_error: "Не удалось обновить пользователя",
  update_user_title: "Редактирование пользователя",
  user_delete_user_question: "Вы действительно хотите удалить пользователя?",
  user_delete_user_success: "Пользователь удален",
  user_delete_user_error: "Не удалось удалить пользователя",

  list_Session_title: "Список сессий",

  listFirmWareCompanies_title: "Список компаний с доступом",
  listFirmWareModal_title: "Предоставление доступа компаниям",
  listCompanyWithAccess: "Компании с доступом",
  listCompanyWithOutAccess: "Компании без доступа",
  chosen: "выбрано",
  firmWare_access_success: "Доступ предоставлен",
  firmWare_access_error: "Не удалось предоставить доступ",

  download_firmWare_error: "Не удалось скачать прошивку",
  download_firmWare_success: "Прошивка успешно скачана",

  delete_firmWare_success: "Прошивка успешно удалена",
  delete_firmWare_error: "Не удалось удалить прошивку",
  delete_firmWare_question: "Вы действительно хотите удалить прошивку?",

  roles_title: "Роли",
  current_role: "Текущие роли",
  all_roles: "Все роли",
  role_assigned_success: "Роль успешно назначена",
  role_assigned_error: "Не удалось назначить роль",

  session_list: "Журнал обновления",
};
