export const caseCreationDateSort = (rowA: any, rowB: any) => {
  const a = rowA.creationDate;
  const b = rowB.creationDate;
  if (a > b) {
    return 1;
  }
  if (b > a) {
    return -1;
  }
  return 0;
};
export const caseRefreshTokenExpiryTimeSort = (rowA: any, rowB: any) => {
  const a = rowA.refreshTokenExpiryTime;
  const b = rowB.refreshTokenExpiryTime;
  if (a > b) {
    return 1;
  }
  if (b > a) {
    return -1;
  }
  return 0;
};
