import React from "react";
import "../Login.scss";
import { EntryPageViewState } from "../LoginTypes";
import { NotificationType } from "../../Notification/notificationTypes";
import { LoginResponseDTO, UserLoginDTO } from "../../../api/auth/apiClient";
import { authClient, mapClient } from "../../../api/auth/AxiosInstanse";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { messages } from "../../../i18n/messages";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../store/notificationSlice";
import { AppDispatch } from "../../../store";

type PropsLoginForm = {
  rememberCheckBox: boolean;
  onRememberCheckBox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  changeView: (view: EntryPageViewState) => void;
};

function LoginForm(props: PropsLoginForm) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<UserLoginDTO>({
    mode: "onChange",
  });
  const onSubmit: SubmitHandler<UserLoginDTO> = async (data) => {
    let result;
    try {
      result = await authClient.login(new UserLoginDTO(data));
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.data_receiving_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        }),
      );
    }
    if (
      UpdateTokens(
        result as LoginResponseDTO,
        /*props.showNotification,*/
        messages.logIn_message_success,
        messages.login_message_errors,
        messages.login_email_notConfirm,
        data.email,
      )
    ) {
      navigate("/");
    }
  };
  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <h2>{messages.login_title}</h2>
        <span>{messages.login_subtitle}</span>
      </div>
      <fieldset>
        <ul>
          <li>
            <label htmlFor="login">{messages.login}</label>
            <input
              {...register("email", {
                required: messages.input_required,
              })}
              type="email"
              id="login"
            />
            <div className="mt-1">
              {errors?.email && <p>{errors?.email?.message || "Errors"}</p>}
            </div>
          </li>
          <li>
            <label htmlFor="password">{messages.password}</label>
            <input
              {...register("password", {
                required: messages.input_required,
              })}
              type="password"
              id="password"
            />
            <div className="mt-1">
              {errors?.password && (
                <p>{errors?.password?.message || "Errors"}</p>
              )}
            </div>
          </li>
          <label className="flex justify-start items-center mb-2 mr-2">
            <input
              className="mr-2"
              checked={props.rememberCheckBox}
              onChange={props.onRememberCheckBox}
              type="checkbox"
              name="_remember_me"
            />
            {messages.logIn_remember}
          </label>
        </ul>
      </fieldset>
      <button type="submit">{messages.entry}</button>
      <div className="mt-2 pb-5 border-b mr-8">
        <a
          onClick={() => props.changeView(EntryPageViewState.PWReset)}
          href="#"
        >
          {messages.logIn_forgot}
        </a>
      </div>
      <div className="mt-2">
        <span className="pr-1">{messages.login_question}</span>
        <a onClick={() => props.changeView(EntryPageViewState.signUp)} href="#">
          {messages.logIn_create_account}
        </a>
      </div>
    </form>
  );
}
export default LoginForm;

export function UpdateTokens(
  result: LoginResponseDTO,
  successMessage: string,
  failureMessage: string,
  notEmailConfirmed: string,
  email: string,
): boolean {
  if (
    result.succeeded &&
    result.tokens?.accessToken?.token &&
    result?.tokens.accessToken.expiration &&
    result.tokens?.refreshToken?.token &&
    result.tokens.refreshToken.expiration
  ) {
    localStorage.setItem("token", result.tokens?.accessToken?.token);
    localStorage.setItem("refreshToken", result.tokens?.refreshToken?.token);
    localStorage.setItem(
      "expiration",
      "" + result?.tokens.accessToken.expiration.getTime(),
    );
    localStorage.setItem(
      "expirationRefreshToken",
      "" + result.tokens.refreshToken?.expiration?.getTime(),
    );
    localStorage.setItem("roles", result.role!.join(","));
    /*localStorage.setItem("expiration", "" + (Date.now()+10000));*/
    /*showNotification({
      isShow: true,
      type: NotificationType.info,
      message: intl.formatMessage({ id: successMessage }),
    });*/
    return true;
  } else if (result.isEmailConfirmed !== null && !result.isEmailConfirmed) {
    mapClient.sendConfirmationEmail(email);
    showNotification({
      isShow: true,
      type: NotificationType.info,
      message: notEmailConfirmed,
    });
  } else {
    showNotification({
      isShow: true,
      type: NotificationType.error,
      message: failureMessage,
    });
  }
  return false;
}
