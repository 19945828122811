import React from "react";
import "./Notification.scss";
import { NotificationType } from "./notificationTypes";
import { ReactComponent as IconFail } from "./svg/Icon_fail.svg";
import { ReactComponent as IconWarning } from "./svg/Icon_warning.svg";
import { ReactComponent as IconSuccess } from "./svg/Icon_success.svg";
import { ReactComponent as IconClose } from "./svg/close_notification.svg";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { showNotification } from "../../store/notificationSlice";

function Notification(): JSX.Element | null {
  const ShowNotificationIsShow = useSelector(
    (state: RootState) => state.notification.notification.isShow
  );
  const ShowNotificationType = useSelector(
    (state: RootState) => state.notification.notification.type
  );
  const ShowNotificationMessage = useSelector(
    (state: RootState) => state.notification.notification.message
  );
  const dispatch = useDispatch<AppDispatch>();

  if (!ShowNotificationIsShow) {
    return null;
  }

  const onCloseNotification = () => {
    dispatch(
      showNotification({
        isShow: false,
        type: NotificationType.info,
        message: "",
      })
    );
  };

  switch (ShowNotificationType) {
    case NotificationType.error: {
      return (
        <div className="notification bg-[#EC4C47]">
          <div className="white_line"></div>
          <div className="mr-3 ml-3">
            <IconFail />
          </div>
          <div>
            <span>{ShowNotificationMessage}</span>
          </div>
          <div
            onClick={onCloseNotification}
            className="ml-2 mr-4 cursor-pointer"
          >
            <IconClose />
          </div>
        </div>
      );
    }
    case NotificationType.info: {
      return (
        <div className="notification bg-[#35A936]">
          <div className="white_line"></div>
          <div className="mr-3 ml-3">
            <IconSuccess />
          </div>
          <div>
            <span>{ShowNotificationMessage}</span>
          </div>
          <div
            onClick={onCloseNotification}
            className="ml-2 mr-4 cursor-pointer"
          >
            <IconClose />
          </div>
        </div>
      );
    }
    case NotificationType.warning: {
      return (
        <div className="notification_warning">
          <div className="white_line"></div>
          <div className="mr-3 ml-3">
            <IconWarning />
          </div>
          <div>
            <span className="text_warning">{ShowNotificationMessage}</span>
          </div>
          <div
            onClick={onCloseNotification}
            className="ml-2 mr-4 cursor-pointer"
          >
            <IconClose />
          </div>
        </div>
      );
    }
    default:
      return null;
  }
}

export default Notification;
