import React from "react";
import "./ModalNotification.scss";
import { messages } from "../../../../../i18n/messages";

type ModalNotificationTypeProps = {
  modalQuestion: string;
  onClose: () => void;
  onAcceptCloseForm: () => void;
};

function ModalNotification(props: ModalNotificationTypeProps) {
  return (
    <div className="modalChangePassword">
      <div className="modalContainerPassword">
        <div className="modalForm">
          <h2>{props.modalQuestion}</h2>
          <div className="modalButtons">
            <button onClick={props.onAcceptCloseForm}>
              <span>{messages.buttonYes}</span>
            </button>
            <button onClick={props.onClose}>
              <span>{messages.buttonNo}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ModalNotification;
