import React from "react";
import { messages } from "../../../i18n/messages";
import "./UserPage.scss";
import { ReactComponent as Remove } from "./svg/btn-remove.svg";

type FilterComponentProps = {
  filterText: string;
  onFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
};

export const FilterComponent = (props: FilterComponentProps) => (
  <div className="searchBlock">
    <input
      type="text"
      value={props.filterText}
      onChange={props.onFilter}
      placeholder={messages.tableSearch}
    />
    <button className="btn-clear" type="button" onClick={props.onClear}>
      <Remove />
    </button>
  </div>
);
