import React, { useEffect, useState } from "react";
import "./SelectAllTransferList.scss";
import {
  CompanyDTO,
  Firmware,
  ProvideAccessToFirmwareDTO,
} from "../../../../api/auth/apiClient";
import { mapClient } from "../../../../api/auth/AxiosInstanse";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { messages } from "../../../../i18n/messages";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store";
import { showNotification } from "../../../../store/notificationSlice";
import { NotificationType } from "../../../Notification/notificationTypes";

type SelectAllTransferListProps = {
  chosenFirmWare: Firmware | undefined;
  companies: Array<CompanyDTO>;
  setIsShowCompanyListWithFirmWare: (
    isShowCompanyListWithFirmWare: boolean,
  ) => void;
};

function not(a: Array<CompanyDTO>, b: Array<CompanyDTO>) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: Array<CompanyDTO>, b: Array<CompanyDTO>) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: Array<CompanyDTO>, b: Array<CompanyDTO>) {
  return [...a, ...not(b, a)];
}
function SelectAllTransferList(props: SelectAllTransferListProps) {
  const [checked, setChecked] = useState<Array<CompanyDTO>>([]);
  const [firmWareCompanies, setFirmWareCompanies] = useState<Array<CompanyDTO>>(
    [],
  );
  const [filteredFirmWare, setFilteredFirmWare] = useState<Array<CompanyDTO>>();
  const dispatch = useDispatch<AppDispatch>();

  async function getFirmWareCompanies(firmwareId: string) {
    try {
      const response = await mapClient.getFirmwareCompanies(firmwareId);
      setFirmWareCompanies(response.data ?? []);
      setFilteredFirmWare(
        props.companies.filter(
          (company) =>
            !response.data?.map((company) => company.id).includes(company.id),
        ),
      );
    } catch (error) {}
  }
  useEffect(() => {
    getFirmWareCompanies(props.chosenFirmWare?.id ?? "");
  }, [props.chosenFirmWare]);

  async function provideAccessToCompany(firmwareId: string) {
    let response;
    try {
      response = await mapClient.provideAccessToFirmware(
        new ProvideAccessToFirmwareDTO({
          firmwareId: firmwareId,
          companiesId: firmWareCompanies.map((company) => company.id!),
        }),
      );
      if (response.succeeded) {
        props.setIsShowCompanyListWithFirmWare(false);
        dispatch(
          showNotification({
            isShow: true,
            type: NotificationType.info,
            message: messages.firmWare_access_success,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.firmWare_access_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        }),
      );
    }
    await getFirmWareCompanies(firmwareId);
  }

  const leftChecked = intersection(checked, firmWareCompanies);
  const rightChecked = intersection(checked, filteredFirmWare ?? []);

  const handleToggle = (value: CompanyDTO) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = (filteredFirmWare: Array<CompanyDTO>) =>
    intersection(checked, filteredFirmWare).length;

  const handleToggleAll = (filteredFirmWare: Array<CompanyDTO>) => () => {
    if (numberOfChecked(filteredFirmWare) === filteredFirmWare.length) {
      setChecked(not(checked, filteredFirmWare));
    } else {
      setChecked(union(checked, filteredFirmWare));
    }
  };

  const handleCheckedRight = () => {
    setFilteredFirmWare(filteredFirmWare?.concat(leftChecked));
    setFirmWareCompanies(not(firmWareCompanies, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setFirmWareCompanies(firmWareCompanies.concat(rightChecked));
    setFilteredFirmWare(not(filteredFirmWare ?? [], rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (
    title: React.ReactNode,
    firmWareCompanies: Array<CompanyDTO>,
  ) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(firmWareCompanies)}
            checked={
              numberOfChecked(firmWareCompanies) === firmWareCompanies.length &&
              firmWareCompanies.length !== 0
            }
            indeterminate={
              numberOfChecked(firmWareCompanies) !== firmWareCompanies.length &&
              numberOfChecked(firmWareCompanies) !== 0
            }
            disabled={firmWareCompanies.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(firmWareCompanies)}/${
          firmWareCompanies.length
        } ${messages.chosen}`}
      />
      <Divider />
      <List
        sx={{
          width: 300,
          height: 200,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {firmWareCompanies.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItemButton
              key={value.id}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name ?? "" + 1} />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  return (
    <div className="listFirmware">
      <div className="listFirmwareContainer">
        <div className="listFirmwareModalForm">
          <div className="listFirmwareModalFormHeader flex items-center justify-between">
            <h2 className="listFirmwareModalFormHeaderTitle">
              {messages.listFirmWareModal_title}
            </h2>
            <img
              onClick={() => props.setIsShowCompanyListWithFirmWare(false)}
              src="/image/btn-remove/btn-remove.svg"
              alt="Close form"
            />
          </div>
          <Grid
            marginTop={1}
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              {customList(messages.listCompanyWithAccess, firmWareCompanies)}
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              {customList(
                messages.listCompanyWithOutAccess,
                filteredFirmWare ?? [],
              )}
            </Grid>
          </Grid>

          <div className="listFirmwareModalFormFooter flex items-center justify-between mt-4">
            <button
              className="back"
              onClick={() => props.setIsShowCompanyListWithFirmWare(false)}
            >
              <span>{messages.button_back}</span>
            </button>
            <button
              className="btn"
              onClick={() =>
                provideAccessToCompany(props.chosenFirmWare?.id ?? "")
              }
              type="submit"
            >
              <span>{messages.save}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SelectAllTransferList;
