import React from "react";
import "./Header.scss";
import { ReactComponent as HeaderLogo } from "./header_logo/logoHeader.svg";

function Header() {
  return (
    <header>
      <div className="headerContainer">
        <div className="headerLogo">
          <HeaderLogo />
        </div>
      </div>
    </header>
  );
}

export default Header;
