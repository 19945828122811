import React from "react";
import { EntryPageViewState } from "./LoginTypes";
import SingForm from "./SingUp/SingForm";
import LoginForm from "./LoginForm/LoginForm";
import ResetForm from "./PwReset/ResetForm";

type PropsLoginFormContainer = {
  currentView: EntryPageViewState;
  rememberCheckBox: boolean;
  changeView: (view: EntryPageViewState) => void;
  onRememberCheckBox: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function LoginFormContainer(props: PropsLoginFormContainer) {
  function showCurrentView() {
    switch (props.currentView) {
      case EntryPageViewState.signUp:
        return <SingForm changeView={props.changeView} />;
      case EntryPageViewState.logIn:
        return (
          <LoginForm
            rememberCheckBox={props.rememberCheckBox}
            onRememberCheckBox={props.onRememberCheckBox}
            changeView={props.changeView}
          />
        );
      case EntryPageViewState.PWReset:
        return <ResetForm changeView={props.changeView} />;
      default:
        break;
    }
  }
  return <>{showCurrentView()}</>;
}

export default LoginFormContainer;
