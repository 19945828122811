import React from "react";
import "../Login.scss";
import { EntryPageViewState } from "../LoginTypes";
import { ResponseDTO, UserRegisterDTO } from "../../../api/auth/apiClient";
import { authClient } from "../../../api/auth/AxiosInstanse";
import { NotificationType } from "../../Notification/notificationTypes";
import { SubmitHandler, useForm } from "react-hook-form";
import { messages } from "../../../i18n/messages";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../store/notificationSlice";
import { AppDispatch } from "../../../store";

type PropsSingUp = {
  changeView: (view: EntryPageViewState) => void;
};

class UserRegisterDTOWithConfirm extends UserRegisterDTO {
  confirmPassword: string;
  constructor(confirmPassword: string) {
    super();
    this.confirmPassword = confirmPassword;
  }
}
function SingForm(props: PropsSingUp) {
  const dispatch = useDispatch<AppDispatch>();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<UserRegisterDTOWithConfirm>({ mode: "onTouched" });
  const onSubmit: SubmitHandler<UserRegisterDTO> = async (data) => {
    let result = new ResponseDTO();
    try {
      result = await authClient.register(new UserRegisterDTO(data));
    } catch {}
    if (result.succeeded) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.info,
          message: messages.signUp_success,
        }),
      );
      props.changeView(EntryPageViewState.logIn);
    } else {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: messages.signUp_errors,
        }),
      );
    }
  };
  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <h2>{messages.signUp}</h2>
      </div>
      <fieldset>
        <ul>
          <li>
            <label htmlFor="companyToken">{messages.signUp_companyToken}</label>
            <input
              {...register("companyToken", {
                required: messages.input_required,
              })}
              type="text"
              id="companyToken"
            />
            <div className="mt-1">
              {errors?.companyToken && (
                <p>{errors?.companyToken?.message || "Errors"}</p>
              )}
            </div>
          </li>
          <li>
            <label htmlFor="email">{messages.signUp_email}</label>
            <input
              {...register("email", {
                required: messages.input_required,
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: messages.validate_email,
                },
              })}
              type="email"
              id="email"
            />
            <div className="mt-1">
              {errors?.email && <p>{errors?.email?.message || "Errors"}</p>}
            </div>
          </li>
          <li>
            <label htmlFor="password">{messages.signUp_password}</label>
            <input
              {...register("password", {
                required: messages.input_required,
                pattern: {
                  value:
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+_]).{6,30}$/,
                  message: messages.validate_password,
                },
              })}
              type="password"
              id="password"
            />
            <div className="mt-1">
              {errors?.password && (
                <p>{errors?.password?.message || "Errors"}</p>
              )}
            </div>
          </li>
          <li>
            <label htmlFor="confirmPassword">
              {messages.singUp_password_repeat}
            </label>
            <input
              {...register("confirmPassword", {
                required: messages.input_required,
                validate: (value) => {
                  if (watch("password") !== value) {
                    return messages.match_password;
                  }
                },
              })}
              type="password"
              id="confirmPassword"
            />
            <div className="mt-1">
              {errors?.confirmPassword && (
                <p>{errors?.confirmPassword?.message || "Errors"}</p>
              )}
            </div>
          </li>
        </ul>
      </fieldset>
      <button className="mb-2.5" type="submit">{messages.signUp_submit}</button>
      <div className="mt-2 border-t mr-8">
        <div className="mt-2">
            <span>
              {messages.signUp_question}
            </span>
          <a className="ml-2"
              onClick={() => props.changeView(EntryPageViewState.logIn)}
              href="#"
          >
            {messages.signUp_logIN}
          </a>
        </div>
      </div>
    </form>
  );
}

export default SingForm;
