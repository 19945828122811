import { createSlice, PayloadAction } from "@reduxjs/toolkit";

enum NotificationType {
  info,
  error,
  warning,
}
export interface NotificationState {
  isShow: boolean;
  type: NotificationType;
  message: string;
}

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notification: {
      isShow: false,
      type: NotificationType.error,
      message: "",
    },
  },
  reducers: {
    onShowNotification: (state, action: PayloadAction<NotificationState>) => {
      state.notification = action.payload;
    },
    onHideNotificationState: (state) => {
      state.notification.isShow = false;
    },
  },
});

export function showNotification(notification: NotificationState) {
  return (dispatch: any) => {
    dispatch(onShowNotification(notification));
    setTimeout(() => {
      dispatch(onHideNotificationState());
    }, 3000);
  };
}

export const { onShowNotification, onHideNotificationState } =
  notificationSlice.actions;

export default notificationSlice.reducer;
